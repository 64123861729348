<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
          <iq-card>
            <template v-slot:headerAction>
              <b-button variant="primary" @click="pdfExport" class="mr-2">
                {{  $t('globalTrans.print') }}
              </b-button>
            </template>
            <template v-slot:body>
              <b-overlay :show="loading">
                <b-row mt-5>
                  <b-col md="12" class="table-responsive">
                    <div>
                      <h6>{{ $t('pump_install.application_id') }}: {{agreementData.application_id}}</h6><br />
                      <h6 style="margin-top:-20px">{{ $t('pump_install.applicant_name') }}: {{ $i18n.locale === 'bn' ? $t(agreementData.name_bn) : agreementData.name }}</h6><br />
                      <b-table-simple bordered hover small caption-top responsive aria-hidden="loading | listReload ? 'true' : null">
                        <colgroup><col><col></colgroup>
                        <colgroup><col><col><col></colgroup>
                        <colgroup><col><col></colgroup>
                        <b-thead>
                          <b-tr>
                            <b-th class="text-center">{{ $t('pump_install.agreement_details') }}</b-th>
                          </b-tr>
                            <b-tr>
                              <th scope="col" v-html="$i18n.locale === 'bn' ? $t(agreementData.agreement_details_bn) : agreementData.agreement_details"></th>
                          </b-tr>
                        </b-thead>
                      </b-table-simple>
                    </div>
                  </b-col>
                </b-row>
              </b-overlay>
            </template>
          </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '../../../../../config/pluginInit'
import RestApi, { irriSchemeServiceBaseUrl } from '../../../../../config/api_config'
import { agreementPdf } from '../../api/routes'
import ExportPdf from '@/Utils/export-pdf'

export default {
  props: ['id', 'scheme_application_id'],
  components: {
  },
  mounted () {
    core.index()
  },
  data () {
    return {
      saveBtnName: this.$t('globalTrans.save'),
      agreement: {
        scheme_application_id: this.$props.id,
        agreement_details: '',
        agreement_details_bn: ''
      },
      agreementData: [],
      org_id: 3
    }
  },
   created () {
    this.loadData()
  },
  methods: {
    async loadData () {
        this.loading = true
        const result = await RestApi.getData(irriSchemeServiceBaseUrl, `${agreementPdf}/${this.$props.scheme_application_id}`)
        if (result.success) {
          this.agreementData = result.data
        }
        this.loading = false
    },
    pdfExport () {
        const rowData = this.getPdfData()
        const id = this.$n(this.agreementData.application_id, { useGrouping: false })
         const labels = [
          { text: this.$t('pump_install.application_id') + ': ' + id, fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) }
        ]
        const name = this.$i18n.locale === 'bn' ? this.agreementData.name_bn : this.agreementData.name
        const labels1 = [
          { text: this.$t('pump_install.applicant_name') + ': ' + name, fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) }
        ]
        rowData.unshift(labels, labels1)
        const reportTitle = this.$i18n.locale === 'en' ? 'Agreement Details ' : 'চুক্তির বিবরণ'
        ExportPdf.exportPdf(irriSchemeServiceBaseUrl, '/report-heading/detail', this.org_id, reportTitle, rowData, null)
    },
    getPdfData () {
        let formatedText = this.$i18n.locale === 'en' ? this.agreementData.agreement_details : this.agreementData.agreement_details_bn
        formatedText = formatedText.replace('<p>', '\n')
        formatedText = formatedText.replace('</p>', '')
        const agreementDetails = [
            { text: formatedText, fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) }
        ]
        const agreeDetail = []
        agreeDetail.push(agreementDetails)
        return agreeDetail
    }
  }
}
</script>
